 <template>
  <div>
    <el-card class="mt ml mr">
      <div class="gurrent">
        <p class="p1 fl">{{ $t("store.logo") }}</p>
        <img
          src="@/assets/Rectangle_297.png"
          v-if="logoUrl === ''"
          style="width: 100px; height: 100px"
          class="fl ml"
        />
        <el-image
          v-else
          :src="logoUrl + '?x-oss-process=style/w128'"
          style="width: 100px; height: 100px"
          class="fl ml"
        ></el-image>
        <MaterialSelector
          :count="1"
          type="image"
          style="margin-left: 50px"
          @receiver="handleSelectionResult($event, 1)"
        >
          <template v-slot:custom-open-button>
            <p class="p2 fl">
              {{ $t("store.avatar") }}<br />
              <span style="color: #000000; font-size: 13px">{{
                $t("store.file")
              }}</span>
            </p>
          </template>
        </MaterialSelector>
        <!-- <input
          type="file"
          ref="upload"
          style="display: none"
          accept="image/gif,image/jpeg,image/png,image/jpg"
          @change="freshImg"
        />

        <img
          src="@/assets/Rectangle_274.jpg"
          v-if="logoUrl == ''"
          @click="uploadImg"
          class="fl ml"
        />
        <img
          :src="logoUrl"
          v-if="logoUrl != ''"
          @click="uploadImg"
          class="fl ml avatar"
        /> -->

        <div class="clear"></div>
      </div>
      <el-form :model="data" :rules="rules" ref="data" label-width="10%">
        <el-form-item
          prop="name"
          class="name mt"
          :label="this.$t('store.name')"
        >
          <el-input
            v-model="data.storeName"
            placeholder=""
            class="input1"
          ></el-input>
        </el-form-item>

        <el-form-item
          prop="mobile"
          class="name mt"
          :label="this.$t('store.mobile')"
        >
          <el-input
            v-model="data.mobile"
            placeholder=""
            class="input2"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="address"
          class="name mt"
          :label="this.$t('store.address')"
        >
          <el-input
            v-model="data.address"
            placeholder=""
            class="input3"
          ></el-input>
        </el-form-item>

        <el-form-item
          prop="email"
          class="name mt"
          :label="this.$t('store.email')"
        >
          <el-input
            v-model="data.email"
            placeholder=""
            class="input4"
          ></el-input>
        </el-form-item>

        <el-form-item
          prop="profile"
          class="name mt"
          :label="this.$t('store.profile')"
        >
          <!-- <el-input
            v-model="data.profile"
            placeholder=""
            class="input5"
            :rows="10"
          ></el-input> -->

          <quill-editor
            class="editor"
            ref="myTextEditor"
            v-model="data.profile"
            :options="editorOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
            @change="onEditorChange($event)"
          >
          </quill-editor>
        </el-form-item>
        <el-form-item>
          <el-button class="button" @click="updateData">{{
            $t("store.preservation")
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { AddStore, getStore, editStore } from "../../utils/api";
import MaterialSelector from "@/components/MaterialSelector";
export default {
  components: {
    MaterialSelector,
  },
  data() {
    var email = (rule, value, callback) => {
      const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t("login.emailreg")));
      }
    };
    return {
      logoUrl: "",
      postLogo: "",
      storeId: "",
      content: null,
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            //["link", "image", "video"], // 链接、图片、视频
          ], //工具菜单栏配置
        },
        placeholder: this.$t("store.productDescription"), //提示
        // placeholder=this.$t('store.productDescription'),
        readyOnly: false, //是否只读
        theme: "snow", //主题 snow/bubble
        syntax: true, //语法检测
      },

      data: {
        logo: "",
        name: "",
        mobile: "",
        email: "",
        address: "",
        profile: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$t("store.names"),
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: this.$t("store.mobileRequired"),
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: this.$t("store.addresss"),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t("login.emails"),
            trigger: "blur",
          },
          { validator: email, trigger: "blur" },
        ],
        profile: [
          {
            required: true,
            message: this.$t("store.profiles"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getStoreDetail();
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quillEditor;
    },
  },
  mounted() {
    // console.log('this is my editor',this.editor);
  },
  methods: {
    getStoreDetail() {
      let userInfo = JSON.parse(localStorage.getItem("vendor_user"));
      this.storeId = userInfo.store.id;
      getStore({
        id: userInfo.store.id,
      }).then((res) => {
        // console.log("0000000", res);
        this.data = res.data;
        this.logoUrl = res.data.logo;
      });
    },
    handleSelectionResult(result, type) {
      // console.log("result, type:", result, type);
      if (type === 1) {
        // 主图
        this.logoUrl = result.filename;
        // this.gallery = [...gallery];
        // console.log("this.logoUrl:", this.logoUrl);
      }
    },
    updateData() {
      if (this.storeId) {
        editStore({
          id: this.storeId,
          logo: this.logoUrl,
          storeName: this.data.storeName,
          mobile: this.data.mobile,
          email: this.data.email,
          address: this.data.address,
          profile: this.data.profile,
        }).then((res) => {
            console.log('res.message',res);
          if (res.errcode == 0) {
            this.$message({
              message: this.$t("common.updateSuccess"),
              type: "success",
            });
          // 刷新页面
          this.$router.go(0);
          }
        });
      } else {
        this.$refs["data"].validate((valid) => {
          if (valid) {
            // this.data.logo = this.postLogo ? this.data.logo : "";
            AddStore({
              logo: this.logoUrl,
              storeName: this.data.storeName,
              mobile: this.data.mobile,
              email: this.data.email,
              address: this.data.address,
              profile: this.data.profile,
            }).then((res) => {
              if (res.message == "Store updated successfully.") {
                this.$message({
                  message: this.$t("common.updateSuccess"),
                  type: "success",
                });
              }
            });
          }
        });
      }
    },
    uploadImg() {
      this.$refs["upload"].click();
    },
    freshImg(e) {
      let _this = this;
      _this.imgObj = e.target.files["0"]; //得到上传的第一个文件
      let fr = new FileReader(); //异步读取存储在用户计算机上的文件（或原始数据缓冲区）的内容
      fr.onload = function () {
        //在读取操作完成时触发
        _this.logoUrl = fr.result;
        _this.postLogo = _this.logoUrl;
        _this.data.logo = _this.logoUrl.replace(/^data:image\/\w+;base64,/, "");
      };
      fr.readAsDataURL(_this.imgObj); //将读取到的文件编码成Data URL
    },

    // 失去焦点
    onEditorBlur() {
      // console.log(editor);
    },
    // 获得焦点
    onEditorFocus() {
      // console.log(editor);
    },
    // 开始
    onEditorReady() {
      // console.log(editor);
    },
    // 值发生变化
    onEditorChange(editor) {
      this.content = editor.html;
      // console.log(editor);
    },
  },
};
</script>

<style scoped>
.avatar {
  width: 70px;
  height: 70px;
}
.gurrent {
  margin: 20px 0 0 20px;
}
.gurrent .p1 {
  font-size: 16px;
  font-weight: 700;
}
.gurrent .p2 {
  margin: 30px 0 0 60px;
  color: #ff0a0a;
  cursor: pointer;
}
.name /deep/ .el-input__inner {
  width: 30%;
}
/* .name .input1 {
  width: 400px;
}
.name .input2 {
  width: 406px;
}
.name .input3 {
  width: 400px;
}
.name .input4 {
  width: 426px;
}
.name .input5 {
  width: 400px;
}
.name span {
  margin-right: 10px;
  font-size: 13px;
  font-weight: 700;
} */
.button {
  width: 218px;
  height: 51px;
  background: #ff0a0a;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  margin: 80px 0 0 32px;
}

.editor {
  line-height: normal !important;
  height: 400px;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
</style>